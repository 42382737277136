export const features = [
  {
    title: {
      "en": 'Damage Detection', 
      "es": "Detección de daños",
      "pt": "Detecção de danos"
    },
    text: {
      "en": 'Detect 21 types of damages across metal, plastic, fiber, glass and rubber parts', 
      "es": "Detecta 21 tipos de daños en piezas de metal, plástico, fibra, vidrio y caucho.",
      "pt": "Detecta 21 tipos de danos em peças de metal, plástico, fibra, vidro e borracha."
    },
    img: 'damage-detection.svg',
    path: '/damage-detection',
    bgColor: '#fff',
    action: true,
  },
  {
    title: {
      "en": 'Fraud Detection',
      "es": "Detección de fraude",
      "pt": "Detecção de fraudes"
    },
    text: {
      "en": 'Specialised fraud detection using photos/videos e.g., old/prior damages, metadata analysis',
      "es": "Detección de fraude especializada mediante fotografías/vídeos, por ejemplo, daños antiguos/anteriores, análisis de metadatos",
      "pt": "Detecção especializada de fraudes usando fotos/vídeos, como danos antigos/anteriores, análise de metadados"
    },
    img: 'fraud-detection.svg',
    path: '/fraud-detection',
    bgColor: '#fbfbfb',
    action: true,
  },
  {
    title: {
      "en": 'Claim Assessment',
      "es": "Evaluación de reclamos",
      "pt": "Avaliação de sinistros"
    },
    text: {
      "en": "Claim estimation & review products customised for each market's repair practices",
      "es": "Productos de estimación y revisión de reclamos personalizados para las prácticas de reparación de cada mercado.",
      "pt": "Produtos de estimativa e revisão de sinistros personalizados para as práticas de reparo de cada mercado."
    },
    img: 'claim.svg',
    path: '/claim-estimation',
    bgColor: '#fff',
    action: true,
  },
  {
    title: {
      "en": 'Text Scanning',
      "es": "Escaneo de texto",
      "pt": "Escaneamento de texto"
    },
    text: {
      "en": 'Auto-read Odometer, VIN, License plate or any other text on the vehicle',
      "es": "Odómetro de lectura automática, VIN, matrícula o cualquier otro texto en el vehículo",
      "pt": "Leitura automática de odômetro, VIN, placa ou qualquer outro texto no veículo"
    },
    img: 'text-scanning.svg',
    path: '/',
    bgColor: '#fbfbfb',
    action: false,
  },
  {
    title: {
      "en": 'Photo & Video Capture App for Vehicle Inspection',
      "es": "Aplicación de captura de fotografías y vídeos para inspección de vehículos",
      "pt": "Aplicativo de captura de fotos e vídeos para inspeção de veículos"
    },
    text: {
      "en": 'Web apps for high quality photo and video capture by the end customer',
      "es": "Aplicaciones web para captura de fotografías y vídeos de alta calidad por parte del cliente final",
      "pt": "Aplicativos web para captura de fotos e vídeos de alta qualidade pelo cliente final"
    },
    img: 'photo-video-capture-2.svg',
    path: '/photo-video-capture',
    bgColor: '#fff',
    action: true,
  },
  {
    title: {
      "en": 'Real Time Guidance with Vehicle Inspection App',
      "es": "Orientación en tiempo real con la aplicación de inspección de vehículos",
      "pt": "Orientação em tempo real com o aplicativo de inspeção de veículos"
    },
    text: {
      "en": 'Real time feedback to customers on quality of captured photos/videos',
      "es": "Comentarios en tiempo real a los clientes sobre la calidad de las fotos/vídeos capturados.",
      "pt": "Feedback em tempo real para os clientes sobre a qualidade das fotos/vídeos capturados."
    },
    img: 'real-time-guidance.svg',
    path: '/',
    bgColor: '#fbfbfb',
    action: false,
  },
];

export const whyCards = [
  {
    title: {
      "en": 'Accurate',
      "es": "Preciso",
      "pt": "Preciso"
    },
    text: {
      "en": '95-99% accuracy in damage detection',
      "es": "95-99% de precisión en la detección de daños",
      "pt": "95-99% de precisão na detecção de danos"
    },
    img: 'accurate.svg',
  },
  {
    title: {
      "en": 'Quick',
      "es": "Rápido",
      "pt": "Rápido"
    },
    text: {
      "en": 'It takes us a few seconds to assess a vehicle',
      "es": "Tardamos unos segundos en valorar un vehículo",
      "pt": "Levamos alguns segundos para avaliar um veículo"
    },
    img: 'quick.svg',
  },
  {
    title: {
      "en": 'Secure GDPR',
      "es": "Secure GDPR",
      "pt": "Seguro GDPR"
    },
    text: {
      "en": 'GDPR compliant, secure AWS/Azure cloud',
      "es": "Nube AWS/Azure segura y compatible con GDPR",
      "pt": "Nuvem segura AWS/Azure compatível com GDPR"
    },
    img: 'secure-gdpr.svg',
  },
  {
    title: {
      "en": 'No manual setup',
      "es": "Sin configuración manual",
      "pt": "Sem configuração manual"
    },
    text: {
      "en": 'Our APIs are easy to integrate with any car inspection tool',
      "es": "Nuestras API son fáciles de integrar con cualquier herramienta de inspección de automóviles",
      "pt": "Nossas APIs são fáceis de integrar com qualquer ferramenta de inspeção de veículos"
    },
    img: 'no-manual-setup.svg',
  },
];

export const testimonials = [
  {
    id: 0,
    name: 'Michel Houthoff',
    position: 'HolidayCars.com COO/CTO',
    text: "By leveraging Inspektlabs' technology, HolidayCars.com can deliver detailed inspection reports, allowing customers to use this information as a reference and enjoy a hassle-free rental experience",
    img: 't0.jpeg',
  },
  {
    id: 1,
    name: 'Joris van Poppel',
    position: 'Chief Product Officer of Fixico',
    text: 'In the past two years, our collaboration with Inspektlabs helped us to further optimize the quality of our digital damage assessment enabling our repairers to provide top quality repairs for our customers',
    img: 't1.png',
  },
  {
    id: 2,
    name: 'Mika Hasegawa',
    position: 'NTT Data',
    text: 'We believe that the greatest strength of their technology is the AI accuracy with a large dataset of over several million images. In fact, the technical evaluation confirmed that their AI accuracy was very high and practical enough',
    img: 't2.jpg',
  },
  {
    id: 3,
    name: 'Marco Moreno',
    position: 'CEO and Chairman of AGE',
    text: 'Inspektlabs software offers + 95% accuracy, and we hope this will also advance our sustainability commitment, Repair before Replace.',
    img: 't3.png',
  },
];
