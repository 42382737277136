export const partners = [
  {
      "name": {
          "en": "Clients",
          "es": "Clientes",
          "pt": "Clientes"
      },
      "logo": [
          "clients/liberty-mutual.png",
          "clients/bmw.png",
          "clients/dekra.png",
          "clients/fixico.png",
          "clients/holiday-cars.png",
          "clients/ntt-data-services.png",
          "clients/kotak.jpg",
          "clients/age.png"
      ]
  },
  {
      "name": {
          "en": "Investors",
          "es": "Inversores",
          "pt": "Investidores"
      },
      "logo": [
          "techstars.png",
          "barclays.png",
          "titancapital.png",
          "bettercapital.svg"
      ]
  },
  {
      "name": {
          "en": "Partners",
          "es": "Socios",
          "pt": "Parceiros"
      },
      "logo": [
          "mitchell.png",
          "gt-motive.png",
          "plug-and-play.jpg",
          "mindtree.jpg",
          "aws.png",
          "microsoft.png"
      ]
  }
];

export const awards = [
  {
    name: 'Winner NTT Data Global innovation contest, Tokyo, 2021',
    img: 'nttopen.png',
    url: 'https://in.nttdata.com/en/news/press-release/2021/february/result-of-open-innovation-contest-11',
  },

  {
    name: 'Zurich Innovation Championship: Inspektlabs e Minalea vencem fase nacional, 2023',
    img: 'zurich.svg',
    url: 'https://www.zurich.com.pt/pt-pt/a-zurich/sala-de-imprensa/comunicados-imprensa/2023/3-abr/',
  },
  {
    name: 'Top 10 global startups from Americas, Insurtech Insights, 2021',
    img: 'insurtech.png',
    url: 'https://www.insurtechinsights.com/future-50-americas/',
  },
];

export const mailFormat =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
